import React, { useState } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';
const FORM_ID = 'orientation_date';

const formConfig = [
  {
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    label: (
      <>
        <>
          Please select from below the date and location of the mandatory summer
          camp orientation that you will attend this year.
        </>
        <br />
        <>
          At the orientation, you'll learn about camp life, your J-1 visa, your
          flight arrangements, insurance, how to create a great first
          impression, and importantly, your travel plans!
        </>
      </>
    ),
  },
  {
    name: 'Orientation Date',
    label: 'Orientation Date & Place',
    model: DATA_MODELS.deal
  },
  {
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    label: (
      <>
        If feel you cannot attend any of the above dates, please email{' '}
        <a href="mailto:camps@bunac.org.uk">camps@bunac.org.uk</a>
      </>
    ),
  },
];

const OrientationDate = () => {
  const [submitted, setSubmited] = useState(false);

  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return (
      <ThankYou
        title="Thanks for applying!"
        text="Please follow the instructions in your Mail Inbox"
        button="Go to MyBunac"
      />
    );
  }

  return (
    <Form
      headline="Orientation Date"
      formId={FORM_ID}
      config={formConfig}
      onSubmit={submit}
      initValues={{}}
    />
  );
};

export default OrientationDate;